.add-user {
    &__wrapper {
        position: relative;
        // height: 80vh;
        background-color: $neutral-10;
        border-radius: 12px;

        padding-bottom: 30px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 40px;
    }

    &__icon-back {
        position: absolute;
        top: 28px;
        left: 28px;
        cursor: pointer;
    }

    &__steps {
        position: relative;
        width: 167.2px;
        display: flex;
        justify-content: space-between;

        padding-top: 54px;

        svg {
            circle {
                fill: $neutral-10;
                stroke: $neutral-50;
                stroke-width: 2px;
            }
            path {
                fill: $neutral-50;
            }
        }

        .line {
            position: absolute;
            left: 32.75px;
            bottom: 31px;
        }

        .step {
            &_active {
                svg {
                    circle {
                        fill: $primary-main;
                        stroke: $primary-main;
                    }
                    path {
                        fill: $neutral-10;
                    }
                }
            }
            &_non-active {
            }
        }
    }

    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        z-index: 1;

        &-title {
            color: $neutral-50;
            text-align: center;
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &__title {
        color: $neutral-100;

        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    &__form {
        padding-top: 48px;

        .form_active {
            display: flex;
            flex-direction: column;
            justify-self: center;
            gap: 5px;
        }

        &-step {
            display: none;
        }
    }
}

