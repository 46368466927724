.document-card {
    display: flex;
    margin-top: 40px;

    .document-card_data {
        width: 70%;
    }

    .document-card_details {
        width: 30%;
        margin-left: 12px;
        border-radius: 12px;
        border: 1px solid $neutral-60;
        padding: 24px 20px;
        height: fit-content;
    }
}

.document-card_data-step {
    border-radius: 12px;
    border: 1px solid $primary-main;
    padding: 28px 20px 28px 40px;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    .document-card_data-step_line {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .document-card_data-step_content-text {
        font-weight: 500;
        color: $neutral-100;
        font-size: 28px;
        line-height: 36px;
    }
}

.document-card_data-step_header {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    .document-card_data-step_status {
        display: flex;
        align-items: center;

        .document-card_data-step_status-name {
            display: flex;
            align-items: center;

            .document-card_data-step_status-name-icon {
                height: 40px;
                width: 40px;
                color: $primary-main;
            }

            .document-card_data-step_status-name-text {
                color: $neutral-100;
                margin-left: 8px;
            }
        }

        .document-card_data-step_status-date {
            margin-left: 32px;
            color: $neutral-60;
            font-weight: normal;
        }
    }

    .document-card_data-step_initiator {
        display: flex;
        margin-left: 40px;


        .document-card_data-step_initiator-title {
            color: $neutral-60;
        }

        .document-card_data-step_initiator-value {
            color: $neutral-100;
            margin-left: 12px;
        }
    }
}

.document-card_data-step_content {
    margin-top: 20px;
}


.document-card_data-step_reason_input {
    margin-top: 24px;

    .document-card_data-step_reason_input_label {
        margin-bottom: 4px;
    }
}

.document-card_data-step_buttons {
    display: flex;
    margin-top: 10px;

    Button {
        margin-right: 8px;
    }
}

.document-card_data-step_items {
    color: $neutral-100;
    font-size: 16px;
    line-height: 20px;

    .document-card_data-step_item {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        .document-card_data-step_item-value {
            width: 100%;
        }
    }
}

.unreached {
    color: $neutral-60 !important;
}

