.btn {
    cursor: pointer;

    padding: 9px 16px;
    border-radius: 12px;
    border: 0;
    text-align: center;
    box-sizing: border-box;
    transition: transform 1s;

    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    display: flex;
    align-items: center;

    flex-direction: row;
    justify-content: center;

    &-icon {
        gap: 6px;
    }

    svg {
        stroke: $neutral-10;
    }

    &__text {
        font-size: 14px;
        color: $neutral-10;
        display: flex;
        text-align: center;
    }

    &_default {
        background: $primary-main;

        &:hover {
            background: $primary-hover;
        }

        &:active {
            background: $primary-pressed;
        }

        &:focus {
            padding: 6px 14px;
            border: 2px solid $primary-focus;
            background: $primary-main;
        }

        &:disabled {
            cursor: not-allowed;
            background: $neutral-20;
            // color: $neutral-50;

            & > .btn__text {
                color: $neutral-50;
            }
        }
    }

    &_outline {
        background: transparent;
        border: 1px solid $primary-main;
        color: $primary-main;
        padding: 7px 15px;

        & > .btn__text {
            color: $primary-main;
        }

        &:hover {
            border-color: $primary-hover;

            & > .btn__text {
                color: $primary-hover;
            }
        }

        &:active {
            border-color: $primary-pressed;
            color: $primary-pressed;

            & > .btn__text {
                color: $primary-pressed;
            }
        }

        &:focus {
            padding: 6px 14px;
            border: 2px solid $primary-focus;
        }

        &:disabled {
            cursor: not-allowed;
            border-color: $neutral-50;

            & > .btn__text {
                color: $neutral-50;
            }
        }
    }

    &-medium {
        width: 164px;
    }

    &-small {
        width: 92px;
    }
}

.btns__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &-toloan {
        gap: 36px;
        padding-top: 32px;
    }
}

.btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 6px;

    &-center {
        justify-content: center;
    }
}

