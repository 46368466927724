.form__block {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .label {
        color: $neutral-90;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .style-italic {
            font-size: 8px;
            font-style: italic;
        }

        .font-size-small {
            font-size: 8px;
        }
    }

    .input {
        width: 416px;
        height: 38px;
        padding: 6px 12px;
        align-items: center;
        border: 0;
        border-radius: 4px;
        background: $background-input;
        color: $neutral-90;
        box-sizing: border-box;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &.full_width {
            width: 100%;
        }

        &:hover {
            border: 1px solid $primary-main;
            background: $neutral-10;
            padding: 6px 11px;
        }

        &:focus {
            border: 3px solid $primary-focus;
            background: $neutral-10;
            padding: 6px 9px;
        }

        &:disabled {
            border: 1px solid $neutral-50;
            background: $neutral-20;
        }
    }

    .password__container {
        position: relative;

        .btn-password {
            position: absolute;
            top: 11px;
            right: 12px;
            opacity: 0.3;
            cursor: pointer;

            &_active {
                opacity: 1;
            }
        }
    }

    .radio {
        display: flex;
        flex-direction: row;
        gap: 19px;

        color: $neutral-100;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &__label {
            display: flex;
            gap: 8px;

            cursor: pointer;
            position: relative;
            padding-left: 28px;
        }

        &__input {
            cursor: pointer;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 1px solid $neutral-50;

            &:after {
                display: none;
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $primary-main;
                margin-top: -6px;
                margin-left: -6px;
            }
        }

        input:checked ~ .checkmark:after {
            display: block;
        }
    }

    .select {
        & > span + span + div {
            border: 0;
            border-radius: 4px;
            background: $background-input;
            box-shadow: none;
            color: $neutral-70;
            // font-family: Poppins;
            font-size: 12px;

            font-weight: 400;
            line-height: 20px;
            appearance: none;

            & > div:first-child {
                padding: 0px 12px;

                div {
                    color: $neutral-70;
                }
            }

            &:hover {
                border: 1px solid $primary-main;
                background: $neutral-10;

                & > div:first-child {
                    padding: 0px 11px;
                }
            }

            &:focus,
            &:active {
                border: 0;
                background: $neutral-10;

                box-shadow: 0 0 0 3px $primary-focus;

                & > div:first-child {
                    padding: 0px 11px;
                }
            }
        }

        & > span + span + div + div {
            font-weight: 400;
            line-height: 20px;
            color: $neutral-100;
        }
    }

    .textarea {
        width: 100%;
        height: 120px;
        padding: 6px 12px;
        box-sizing: border-box;
        border: 0;
        border-radius: 4px;
        background-color: $background-input;
        font-size: 12px;
        resize: none;
        font-weight: 400;
        line-height: 20px;
        color: $neutral-70;
        scrollbar-color: $neutral-40 #ffffff;

        &:hover {
            border: 1px solid $primary-main;
            background: $neutral-10;
            padding: 5px 11px;
        }

        &:focus {
            border: 3px solid $primary-focus;
            background: $neutral-10;
            padding: 3px 9px;
        }

        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        * {
            scrollbar-width: auto;
            scrollbar-color: $neutral-40 #ffffff;
        }

        /* Chrome, Edge, and Safari */
        *::-webkit-scrollbar {
            width: 16px;
        }

        *::-webkit-scrollbar-track {
            background: #ffffff;
        }

        *::-webkit-scrollbar-thumb {
            background-color: $neutral-40;
            border-radius: 10px;
            border: 3px solid #ffffff;
        }

        &::placeholder {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }

    .error {
        width: 416px;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $danger-main;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

