/* DatePicker.css */

.react-datepicker-wrapper {
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    .react-datepicker__input-container {
        cursor: pointer;

        input {
            width: 258px;
            height: 40px;
            padding: 6px 12px 6px 36px;

            border: 1px solid $neutral-40;
            border-radius: 12px;

            color: $neutral-90;

            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            &.large-form_single-date {
                width: 100%;
                height: 38px;
                padding: 6px 12px;
                border: 0;
                border-radius: 4px;
                background: $background-input;
                color: $neutral-90;
                box-sizing: border-box;

                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                &:hover {
                    border: 1px solid $primary-main;
                    background: $neutral-10;
                    padding: 6px 11px;
                }

                &:focus {
                    border: 3px solid $primary-focus;
                    background: $neutral-10;
                    padding: 6px 9px;
                }
            }

            &:hover {
                border-color: $primary-main;
            }

            &:active {
                border-color: $neutral-60;

                &::placeholder {
                    color: $neutral-60;
                }
            }

            &::placeholder {
                color: $neutral-50;
            }
        }

        .calendar__icon {
            color: $neutral-60;

            &--active {
                color: $neutral-90;
            }
        }

        .react-datepicker-ignore-onclickoutside {
            border-color: $neutral-40;
            color: $neutral-90;
            background-color: $neutral-20;
        }

        .calendar__icon.react-datepicker-ignore-onclickoutside {
            color: $neutral-90;
        }
    }
}

.react-datepicker__tab-loop {
    .react-datepicker {
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid $neutral-40;
        background: $neutral-10;

        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        .react-datepicker__navigation {
            display: none;
        }

        .react-datepicker__month-container {
            .react-datepicker__header {
                background-color: $neutral-10;
                border: 0;
                padding: 4px 0 0 0;

                h2 {
                    display: none;
                }
            }

            .react-datepicker__month {
                margin: 0 12px 12px 12px;
            }
        }
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__day--today,
    .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
        color: $primary-main;
        background: $neutral-10;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ).react-datepicker__day--in-selecting-range {
        background: $primary-focus;
        color: $primary-main;
        border-radius: 0;
    }

    .react-datepicker__day--keyboard-selected {
        border-radius: 20px;
    }

    .react-datepicker__day-names {
        margin-bottom: 0;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
        margin: 4px 0;
        width: 34px;
        height: 34px;
        // line-height: 20px;
    }

    .react-datepicker__day-name {
        margin: 0;
        color: $neutral-50;
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
    }

    .react-datepicker__day-name:nth-child(6),
    .react-datepicker__day-name:nth-child(7) {
        color: $danger-main;
    }

    .react-datepicker__day {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        line-height: 20px;

        &:hover {
            border-radius: 20px;
            border: 1px solid $primary-main;
            background: transparent;
            color: $neutral-90;
        }
    }

    .react-datepicker__day--in-range.react-datepicker__day:hover {
        border-radius: 0;
        border: 0;
        background: $primary-focus;
        // color: $neutral-90;
        // font-weight: bold;
    }

    .react-datepicker__input-container input:not(:placeholder-shown) {
        background-color: #1068d3; /* Выбери нужный цвет фона */
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--keyboard-selected.react-datepicker__day--today.react-datepicker__day--range-start,
    .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ).react-datepicker__day--selecting-range-start {
        background: $primary-main;
        color: $neutral-10;
        border-radius: 20px 0px 0px 20px;
    }

    .react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__day--keyboard-selected.react-datepicker__day--today.react-datepicker__day--range-end,
    .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ).react-datepicker__day--selecting-range-end {
        background: $primary-main;
        color: $neutral-10;
        border-radius: 0px 20px 20px 0px;
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end,
    .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end,
    .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ).react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
        border-radius: 20px;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
        border: 0;
        color: $primary-main;
        background-color: $neutral-10;
        margin: 8px;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}

// .react-datepicker__day--selected,
// .react-datepicker__day--keyboard-selected {
//     background-color: #0d161f;
//     color: white;
// }

// .react-datepicker__day:hover {
//     background-color: #0056b3;
//     color: white;
// }

.react-datepicker__close-icon,
.react-datepicker__close-icon::after {
    display: none;
    padding: 0;
}

.react-datepicker__input-container.react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 0;
    left: 12px;
    top: 12px;
    width: auto;
    height: auto;

    &.large-form_single-date {
        //пока все что придумал как сдвинуть это сраный календарить на правую сторону
        left: 96%;
    }
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}

.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    background: $primary-focus;
}

.react-datepicker__children-container.react-datepicker__children-container {
    width: auto;
    margin: 12px;
    /* padding-right: 0.2rem; */
    /* padding-left: 0.2rem; */
    height: auto;
}

.calendar {
    position: relative;

    &__buttons-wrapper {
        display: flex;
        justify-content: space-between;

        padding: 0 0.5px;
    }
}

.react-datepicker__input {
    background-color: $neutral-20;
}
