.loan {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    .title {
        color: #1e1e1e;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &-equipment-list {
        display: flex;
        padding: 24px 40px 32px 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        width: 880px;

        border-radius: 12px;
        border: 1px solid $neutral-40;

        .list {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .equipment-info {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 92px 300px;
            gap: 48px;

            .equipment {
                display: flex;
                align-items: center;
            }
            .counter {
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 10px 12px;
                border-radius: 4px;
                background: $background-input;

                .sub,
                .add {
                    cursor: pointer;
                    background: $background-input;
                    border: 0;
                    display: flex;
                    justify-content: center;
                }
            }

            .comment {
                width: 300px;
                height: 38px;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                padding: 9px 12px;
                border-radius: 4px;
                background: $background-input;
                border: 0;
            }
        }
    }

    &-info {
        display: flex;
        padding: 24px 40px;
        flex-direction: column;
        // align-items: center;
        gap: 20px;
        align-self: stretch;

        border-radius: 12px;
        border: 1px solid $neutral-40;

        .info-form {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}

