.equipment-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 12px;
    border: 1px solid $neutral-40;
    background-color: $neutral-10;
    overflow: hidden;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &__link {
        cursor: pointer;
    }

    .table {
        border-bottom: 1px solid $neutral-40;

        &:last-child {
            border-bottom: 0;
        }
    }

    &-row {
        display: grid;
        grid-template-columns: 250px 560px 1.3fr 1.1fr;
        min-height: 64px;
        border-bottom: 1px solid $neutral-40;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background-color: $neutral-20;
        }

        &__check {
            grid-template-columns: 52px 250px 560px 1.3fr 1.1fr;

            &:hover {
                background-color: $neutral-10;
            }
        }

        div {
            display: flex;
            align-items: center;
            // height: 56px;
            padding: 18px 16px;
        }

        &__id {
            overflow: auto;
            text-overflow: ellipsis;
        }

        &__name {
            overflow: auto;
            text-overflow: ellipsis;
        }
    }

    &-head {
        // height: 44px;
        min-height: 44px;
        background-color: $neutral-10;

        & > div > p {
            color: $neutral-70;
        }

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &:hover {
            background-color: $neutral-10;
        }
    }

    .table-group {
        display: flex;
        justify-content: space-between;
        height: 56px;
        padding: 18px 16px;
        border-bottom: 1px solid $neutral-40;
        background: $primary-bg-color;

        &_close {
            border-bottom: 0;
        }

        &-items {
            &_open {
                display: block;
            }

            &_close {
                display: none;
            }
        }
    }

    .check {
        display: flex;
        align-items: center;

        .custom-checkbox {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 0.5px solid $neutral-50;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
            background-color: $neutral-10;
            appearance: none;
            -webkit-appearance: none;
            cursor: pointer;

            &:checked {
                border-color: $primary-main;
                background-color: $primary-main;
                background-image: url("../../assets/img/Icons/CheckBoxCheck.svg");
            }

            & + label {
                cursor: pointer;
                padding-left: 10px;
            }
        }
    }

    .chevron {
        cursor: pointer;
        stroke: $neutral-100;

        &_close {
            transform: rotate(-90deg);
        }
    }
}

